<template>
	<div class="btn_wrapper">
		<a :href="localeLink('weddingsite')" class="wedding_det_btn">
			<div class="wedding_details_btn cut-text">
				<span class="img-wedding-site"></span>
				<div class="content">
					<span class="text">{{
						$t("vendorDiscovery.wedding_site")
					}}<span class="new-label">{{
							$t("vendorDiscovery.new")
						}}</span></span>
					<span class="edit-label">{{
						$t("vendorDiscovery.set_now_detail")
					}}</span>
				</div>
			</div>
		</a>
		<nuxt-link
			:to="localeLink('wedding-checklist')"
			class="wedding_det_btn"
		>
			<div class="wedding_details_btn cut-text">
				<span class="imgs-wpt imgs-wpt--planning"></span>
				<div class="content">
					<span class="text"
						>{{ $t("vendorDiscovery.wedding_checklist")
						}}</span
					>
					<span class="edit-label">{{
						$t("vendorDiscovery.set_now_checklist")
					}}</span>
				</div>
			</div>
		</nuxt-link>
		<nuxt-link :to="localeLink('wedding-points')" class="wedding_det_btn">
			<div class="wedding_details_btn cut-text">
				<span
					class="imgs-discovery imgs-discovery--wedding-point"
				></span>
				<div class="content">
					<span class="text">{{
						weddingPointSet
							? $t("vendorDiscovery.wedding_points_value", {
									wedding_point: weddingPoint,
							  })
							: $t("vendorDiscovery.wedding_points")
					}}</span>
					<span class="edit-label">{{
						weddingPointSet
							? $t("vendorDiscovery.view_points")
							: $t("vendorDiscovery.learn_more")
					}}</span>
				</div>
			</div>
		</nuxt-link>
		<nuxt-link
			:to="localeLink('wedding-details/edit')"
			class="wedding_det_btn"
		>
			<div class="wedding_details_btn cut-text">
				<span class="imgs-discovery imgs-discovery--wedding"></span>
				<div class="content">
					<span class="text">{{
						$t("vendorDiscovery.wedding_detail")
					}}</span>
					<span class="edit-label">{{
						$t("vendorDiscovery.set_now_detail")
					}}</span>
				</div>
			</div>
		</nuxt-link>
	</div>
</template>

<script>
import RewardsAPI from "@/api/rewards"
import VendorsAPI from "@/api/vendor"

export default {
	name: "MobileOverview",
	data() {
		return {
			vendorCount: 0,
			weddingPoint: 0,
			weddingPointSet: false,
		}
	},
	async mounted() {
		const rewardsApi = new RewardsAPI(this.$axios)
		const vendorsApi = new VendorsAPI(this.$axios)

		try {
			const { data } = await vendorsApi.getSavedVendors()
			if (data.meta) {
				this.vendorCount = data.meta.totalSaveVendor
			}
		} catch (err) {
			console.log(err)
		}

		try {
			const { data } = await rewardsApi.getRewards()
			if (data.data) {
				this.weddingPoint = data.data.total_point
				this.weddingPointSet = data.data.has_record
			}
		} catch (err) {
			console.log(err)
		}
	},
}
</script>

<style lang="scss" src="./style.scss" scoped />
